.App {
  text-align: center;
}

.App-logo {
  /* width: 15%; /* Adjust this value as needed */
  /* max-height: auto; /* This maintains the aspect ratio */
  /* display: block; /* Centers the image in its container */
  /* margin: 0 auto; /* Centers the image horizontally */
  margin-top: 20%;
}

.img-responsive {
  max-width: 50vw; /* Maximum width to 100% of the viewport width */
  max-height: 50vh; /* Maximum height to 100% of the viewport height */
  object-fit: contain; /* This makes sure that the aspect ratio of the image is preserved */
}

/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/

.App-header {
  /* background-color: #282c34; */
  background-color: #000000;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /*margin-bottom: 10px; /* Reduce this value to decrease space */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}